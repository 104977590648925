<template>
  <div>
    <div class="app-container monthMoneyInfo">
        <section class="titlebox">
          <el-button size="medium" type="text" @click="$router.go(-1)">返回</el-button>
          <div class="title">{{!searchForm.isinfo?`编辑${DataInfo.staffName}工资条`:`${DataInfo.staffName}工资条详情`}}</div>
        </section>
        <section class="viewBox" v-loading="loading">
            <p><span>姓名：{{DataInfo.staffName}}</span> <span>员工工号：{{DataInfo.jobNumber}}</span></p>
            <el-table :data="DataInfo.payrollData" stripe :header-cell-style="tableHeaderColor" style="margin-top:20px">
                <el-table-column v-for="(col,i) in columns"
                    :prop="col.id"
                    align="center"
                    :key="col.id"
                    :label="col.label"
                    :width="col.width">
                    <template slot-scope="{row}">
                        <el-input-number v-model="row[col.id]" v-if="change===true&&i>columns.length-3" :min="0" class="moneyinput" :precision="2" :controls="false"></el-input-number>
                        <span v-else>{{row[col.id]}}</span>
                    </template>
                </el-table-column>
                <el-table-column label="操作" align="center" v-if="!searchForm.isinfo&&(searchForm.isthisMonth||searchForm.grantType!='1')">
                    <el-button type="text" v-if="change===true" @click="cancel()">取消</el-button>
                    <el-button type="text" @click="changepayroll">{{text}}</el-button>
                </el-table-column>
            </el-table>
            <p>工资明细</p>
            <el-table :data="DataInfo.payrollInfoList" :header-cell-style="tableHeaderColor" stripe>
                <el-table-column prop="name" label="项目" align="center"></el-table-column>
                <el-table-column prop="money" label="金额" align="center">
                    <template slot-scope="{row}">
                        <el-input-number v-model="row.money" v-if="row.change" :precision="2" :controls="false" class="moneyinput" ></el-input-number>
                        <span v-else>{{row.money}}</span>
                    </template>
                </el-table-column>
                <el-table-column label="操作" align="center" v-if="!searchForm.isinfo&&(searchForm.isthisMonth||searchForm.grantType!='1')">
                    <template slot-scope="scope">
                        <el-button type="text" v-if="scope.row.change" @click="cancel(scope.row,scope.$index)">取消</el-button>
                        <el-button type="text" @click="changeInfopayroll(scope.row,scope.$index)">{{scope.row.infoText}}</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <p class="moryeInfoTitle">实发工资=<span v-for="(word,i) in DataInfo.payrollConfig" :key="i" style="margin-right:0">{{i==0?'':'+'}}{{word.typeName}}</span></p>
            <div v-for="(word,i) in DataInfo.payrollConfig" :key="i" class="text">
                <div :class="i == 0 ? 'moryeInfoText' : 'moryeInfoCri'"><div class="moryeInfoCriRound"></div>{{word.typeName}}＝<span v-for="(children,i1) in word.children" :key="i1">{{i1==0?'':'+'}}{{children.typeName}}</span></div>
                <!--<ul>
                    <li>月度价值贡献补贴实发＝月度价值贡献补贴基数×价值贡献率</li>
                    <li>税前补款＝补贴合计＋加班合计+奖金合计+差旅补贴（纳税）+其他补款（纳税</li>
                    <li>税前扣款＝其他扣款（纳税</li>
                    <li>社保公积金个人扣款＝养老个人扣款＋失业个人扣款＋医疗个人扣款＋公积金个人扣款</li>
                    <li>税后补款=差旅补贴（免税）＋其他补款（免税）</li>
                    <li>税后扣款＝爱心互助金＋冲借款＋住宿费＋资产遗失赔偿＋补充医疗扣款＋其他扣款（免税）等</li>
                    <li>差旅补贴＝差旅补贴（纳税）＋差旅补贴（免税）</li>
                </ul>!-->
            </div>
        </section>
        <section class="viewBox" v-loading="loading">
            <p>考勤明细</p>
            <el-table :data="DataInfo.payrollData" :header-cell-style="tableHeaderColor" stripe>
                <el-table-column v-for="col in kaoqin"
                    :prop="col.id"
                    align="center"
                    :key="col.id"
                    :label="col.label"
                    :width="col.width">
                    <template slot-scope="{row}">
                        <el-input-number v-model="row[col.id]" v-if="timechange===true" :min="0" class="moneyinput" :precision="1" :controls="false"></el-input-number>
                        <span v-else>{{row[col.id]==='null'?0:row[col.id]}}</span>
                    </template>
                </el-table-column>
                <el-table-column label="操作" align="center" v-if="!searchForm.isinfo&&(searchForm.isthisMonth||searchForm.grantType!='1')">
                    <el-button type="text" v-if="timechange===true" @click="timecancel()">取消</el-button>
                    <el-button type="text" @click="changeTimeoll">{{text}}</el-button>
                </el-table-column>
            </el-table>
            <p class="moryeInfoTitle">【工资清单说明】</p>
            <ol>
                <li class="moryeInfoText">月计薪天数说明：21.75天＝（365天－104天（休息日））÷12个月
                </li>
                <li class="moryeInfoText">月薪计算说明：
                    <div class="moryeInfoCri" style="margin-top:10px"><div class="moryeInfoCriRound"></div>当月入职＝试用期工资÷21.75×实际工作天数</div>
                    <div class="moryeInfoCri"><div class="moryeInfoCriRound"></div>当月转正＝转正后工资÷21.75×转正后工作天数+试用期工资÷21.75×(21.75－转正后工作天数)</div>
                    <div class="moryeInfoCri"><div class="moryeInfoCriRound"></div>当月离职＝当月全勤工资÷21.75×实际工作天数</div>
                    <div class="moryeInfoCri"><div class="moryeInfoCriRound"></div>当月在职且休假</div>
                    <div class="moryeInfoLast">(1)当月全勤工资＝基本工资+岗位工资+绩效工资</div>
                    <div class="moryeInfoLast">(2)病假工资＝当月全勤工资÷21.75÷8×当月病假小时数*20%，若全月病假，当月实发工资不足当地最低工资的80%，按最低工资标准的80%补足</div>
                    <div class="moryeInfoLast">(3)事假工资＝当月全勤工资÷21.75÷8×当月事假小时数*0</div>
                    <div class="moryeInfoLast">(4)年休假/倒休工资＝当月全勤工资÷21.75÷8×当月年休假/倒休小时数*100%</div>
                    <div class="moryeInfoLast">(5)产检假/陪产假/哺乳假工资＝当月全勤工资÷21.75÷8×当月符合国家政策且符合公司考勤管理制度的休假小时数*100%</div>
                    <div class="moryeInfoLast">(6)丧事假工资＝当月全勤工资÷21.75÷8×当月符合公司考勤管理制度的休假小时数*100%</div>
                    <div class="moryeInfoLast">(7)迟到早退工资＝当月全勤工资÷21.75÷8×迟到早退小时数*0</div>
                    <div class="moryeInfoLast">(8)旷工工资＝当月全勤工资÷21.75÷8×旷工小时数*0</div>
                    <div class="moryeInfoLast">(9)欠工时工资＝当月全勤工资÷21.75÷8×欠工时小时数*0</div>
                </li>
            </ol>
            <div style="text-align:center">
                <el-button type="primary" :disabled="btnDisabled()" v-if="!searchForm.isinfo&&(searchForm.isthisMonth||searchForm.grantType!='1')" @click="submitChange()">提交</el-button>
                <el-button type="primary" :disabled="btnDisabled()" v-if="!searchForm.isinfo&&(searchForm.isthisMonth||searchForm.grantType!='1')" @click="submitChange(1)">提交并发放</el-button>
            </div>
        </section>
    </div>
  </div>
</template>

<script>
import {payrollDetail, payrollStaffDetails, payrollUpdate, payrollGrant} from '../../../api/remuneration';
export default {
    data() {
        return {
            columns: [],
            DataInfo: {},
            searchForm: {},
            text: '修改',
            change: false,
            timechange: false,
            whichchange: [],
            loading: false,
            data: [],
            mingxi: [],
            kaoqin: []
        };
    },
    methods: {
        tableHeaderColor({ rowIndex }) {
            if (rowIndex === 0) {
                return 'background-color: #F0F8FF;';
            }
        },
        getDetail() {
            if (this.$route.name == 'currentInfo') {
                this.loading = true;
                payrollDetail({staffId: this.searchForm.staffId, month: this.searchForm.month, detailType: this.searchForm.isthisMonth || this.searchForm.grantType != '1' ? '0' : '1'}).then((res) => {
                    if (res._responseStatusCode == 0) {
                        const obj = {};

                        const moneyArr = [];

                        const timeArr = [];

                        this.columns = [];
                        this.kaoqin = [];
                        if (res.payrollList && res.payrollList.length > 0) {
                            res.payrollList.forEach((it, index) => {
                            if (it.name === '实发工资') {
                                res.payrollList.map((it1, index1) => {
                                    if (index1 <= index) {
                                        moneyArr.push(it1);
                                    } else {
                                        timeArr.push(it1);
                                    }
                                });
                            }
                            });
                            moneyArr.map((it) => {
                                obj[it.randomCode] = it.money;
                                this.columns.push({id: it.randomCode, label: it.name});
                            });
                            timeArr.map((it) => {
                                obj[it.randomCode] = it.money;
                                this.kaoqin.push({id: it.randomCode, label: it.name});
                            });
                            res.paidValue = res.payrollList[res.payrollList.length - 1].money;
                            res.payableValue = res.payrollList[res.payrollList.length - 2].money;
                            res.payrollData = [obj];
                        }
                        if (this.searchForm.isthisMonth || this.searchForm.grantType != '1') {
                            res.copypayrollData = JSON.parse(JSON.stringify([obj]));
                            res.payrollInfoList.forEach((it) => {
                                it.change = false;
                                it.infoText = '修改';
                            });
                            res.copypayrollInfoData = JSON.parse(JSON.stringify(res.payrollInfoList));
                        }
                        this.DataInfo = res;
                        this.loading = false;
                    }
                    this.loading = false;
                });
            } else {
                payrollStaffDetails({staffId: this.searchForm.staffId, month: this.searchForm.month, grantDateTime: this.searchForm.grantDateTime}).then((res) => {
                    if (res._responseStatusCode == 0) {
                        const obj = {};

                        const moneyArr = [];

                        const timeArr = [];

                        this.columns = [];
                        this.kaoqin = [];
                        if (res.payrollList && res.payrollList.length > 0) {
                            res.payrollList.forEach((it, index) => {
                            if (it.name === '实发工资') {
                                res.payrollList.map((it1, index1) => {
                                    if (index1 <= index) {
                                        moneyArr.push(it1);
                                    } else {
                                        timeArr.push(it1);
                                    }
                                });
                            }
                            });
                            moneyArr.map((it) => {
                                obj[it.randomCode] = it.money;
                                this.columns.push({id: it.randomCode, label: it.name});
                            });
                            timeArr.map((it) => {
                                obj[it.randomCode] = it.money;
                                this.kaoqin.push({id: it.randomCode, label: it.name});
                            });
                            res.paidValue = res.payrollList[res.payrollList.length - 1].money;
                            res.payableValue = res.payrollList[res.payrollList.length - 2].money;
                            res.payrollData = [obj];
                        }
                        if (this.searchForm.isthisMonth || this.searchForm.grantType != '1') {
                            res.copypayrollData = JSON.parse(JSON.stringify([obj]));
                            res.payrollInfoList.forEach((it) => {
                                it.change = false;
                                it.infoText = '修改';
                            });
                            res.copypayrollInfoData = JSON.parse(JSON.stringify(res.payrollInfoList));
                        }
                        this.DataInfo = res;
                        this.loading = false;
                    }
                    this.loading = false;
                    //     this.DataInfo=res;
                    //     let obj={}
                    //     this.DataInfo.payrollList.forEach(it=>{
                    //         obj[it.randomCode]=it.money;
                    //         this.columns.push({id:it.randomCode,label:it.name})
                    //     })
                    //     this.DataInfo.payrollData=[obj];
                    //     this.loading=false;
                    // }
                    // this.loading=false;
                });
            }
        },
        btnDisabled() {
            let status = false;

            if (!this.change) {
                this.DataInfo.payrollInfoList && this.DataInfo.payrollInfoList.forEach((it) => {
                    if (it.change == true) {
                        status = true;
                    }
                });
            } else {
                status = true;
            }
            return status;
        },
        cancel(row, index) {
            if (!row) {
                this.change = false;
                this.DataInfo.payrollData = this.DataInfo.copypayrollData;
                this.text = '修改';
            } else {
                this.DataInfo.payrollInfoList[index].change = false;
                this.DataInfo.payrollInfoList[index].money = this.DataInfo.copypayrollInfoData[index].money;
                this.DataInfo.payrollInfoList[index].infoText = '修改';
            }
        },
        timecancel(row, index) {
            if (!row) {
                this.timechange = false;
                this.DataInfo.payrollData = this.DataInfo.copypayrollData;
                this.text = '修改';
            } else {
                this.DataInfo.payrollInfoList[index].timechange = false;
                this.DataInfo.payrollInfoList[index].money = this.DataInfo.copypayrollInfoData[index].money;
                this.DataInfo.payrollInfoList[index].infoText = '修改';
            }
        },
        changepayroll() {
            if (!this.change) {
                this.change = true;
                this.text = '确定';
            } else {
                this.DataInfo.payrollList.map((it) => {
                    for (const key in this.DataInfo.payrollData[0]) {
                        if (it.randomCode == key) {
                            it.money = this.DataInfo.payrollData[0][key];
                        }
                        if (it.name == '应发工资') {
                            this.DataInfo.payableValue = it.money;
                        } else if (it.name == '实发工资') {
                            this.DataInfo.paidValue = it.money;
                        }
                    }
                });
                this.change = false;
                this.whichchange.push('1');
                this.text = '修改';

            }
        },
        changeTimeoll() {
            if (!this.timechange) {
                this.timechange = true;
                this.text = '确定';
            } else {
                this.DataInfo.payrollList.map((it) => {
                for (const key in this.DataInfo.payrollData[0]) {
                    if (it.randomCode == key) {
                        it.money = this.DataInfo.payrollData[0][key];
                    }
                    if (it.name.includes('下月可调休')) {
                        this.DataInfo.adjustableRestNextMonth = it.money;
                    } else if (it.name.includes('本月已调休')) {
                        this.DataInfo.offThisMonth = it.money;
                    } else if (it.name.includes('实际出勤')) {
                        this.DataInfo.actualAttendance = it.money;
                    } else if (it.name.includes('病事假')) {
                        this.DataInfo.sickLeave = it.money;
                    } else if (it.name.includes('本月加班')) {
                        this.DataInfo.overtimeThisMonth = it.money;
                    } else if (it.name.includes('合计加班')) {
                        this.DataInfo.totalOvertime = it.money;
                    }
                }
            });
                this.timechange = false;
                this.whichchange.push('3');
                this.text = '修改';
            }
        },
        changeInfopayroll(row, index) {
            if (!row.change) {
                this.DataInfo.payrollInfoList[index].change = true;
                this.DataInfo.payrollInfoList[index].infoText = '确定';
            } else {
                this.DataInfo.payrollInfoList[index].change = false;
                this.whichchange.push('2');
                this.DataInfo.payrollInfoList[index].infoText = '修改';
            }
        },
        submitChange(send) {
            this.$confirm(send ? `是否确定修改并发放${this.DataInfo.staffName}的工资条` : `是否确定修改${this.DataInfo.staffName}的工资条信息?`, '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                 this.DataInfo.payrollList.map((it) => {
                    for (const key in this.DataInfo.payrollData[0]) {
                        if (it.randomCode == key) {
                            it.money = this.DataInfo.payrollData[0][key];
                        }
                        if (it.name == '应发工资') {
                            this.DataInfo.payableValue = it.money;
                        } else if (it.name == '实发工资') {
                            this.DataInfo.paidValue = it.money;
                        }
                        if (it.name.includes('下月可调休')) {
                            this.DataInfo.adjustableRestNextMonth = it.money;
                        } else if (it.name.includes('本月已调休')) {
                            this.DataInfo.offThisMonth = it.money;
                        } else if (it.name.includes('实际出勤')) {
                            this.DataInfo.actualAttendance = it.money;
                        } else if (it.name.includes('病事假')) {
                            this.DataInfo.sickLeave = it.money;
                        } else if (it.name.includes('本月加班')) {
                            this.DataInfo.overtimeThisMonth = it.money;
                        } else if (it.name.includes('合计加班')) {
                            this.DataInfo.totalOvertime = it.money;
                        }
                    }
                });
                const params = {
                    staffId: this.searchForm.staffId,
                    payableValue: this.DataInfo.payableValue,
                    paidValue: this.DataInfo.paidValue,
                    adjustableRestNextMonth: this.DataInfo.adjustableRestNextMonth,
                    offThisMonth: this.DataInfo.offThisMonth,
                    actualAttendance: this.DataInfo.actualAttendance,
                    sickLeave: this.DataInfo.sickLeave,
                    overtimeThisMonth: this.DataInfo.overtimeThisMonth,
                    totalOvertime: this.DataInfo.totalOvertime,
                    month: this.searchForm.month,
                    payrollUpdateList: this.DataInfo.payrollInfoList
                };

                payrollUpdate(params).then((res) => {
                    if (res.code == 0) {
                        this.whichchange = [];
                        if (send) {
                            const param = {staffIds: [this.searchForm.staffId], grantStatus: '1', month: this.searchForm.month, grantType: this.searchForm.isthisMonth ? '1' : '2'};

                            payrollGrant(param).then((res) => {
                                if (res._responseStatusCode == 0) {
                                    this.$message.success('工资条发放成功');
                                    this.$router.push({name: this.searchForm.isthisMonth ? 'RemunerationCurrent' : 'RemunerationHistory'});
                                }
                            });
                        } else {
                            this.$message.success('工资条修改成功');
                            this.getDetail();
                            this.$router.push({name: this.searchForm.isthisMonth ? 'RemunerationCurrent' : 'RemunerationHistory'});
                        }
                    }
                });
            });
        }
    },
    created() {
        if (this.$route.params.staffId) {
            this.searchForm = this.$route.params;
        } else if (!this.$route.params.staffId && this.$route.name == 'currentInfo') {
            this.searchForm = JSON.parse(sessionStorage.getItem('remunerationInfo'));
        } else if (!this.$route.params.staffId && this.$route.name == 'staffSalaryDetails') {
            this.searchForm = JSON.parse(sessionStorage.getItem('staffremunerationInfo'));
        }
        this.getDetail();
    },
    beforeRouteLeave(to, from, next) {
        next();
    },
    mounted() {
    }
};
</script>
<style lang="scss">
.monthMoneyInfo {
    .viewBox {
        background: #fff;
        padding: 14px 40px;
        p span {
            margin-right:20px;
        }
        >p,.text>p {
            margin: 20px 0;
        }
        >div,.text>div {
            margin-bottom:10px;
        }
        ul>li {
            list-style: inside;
            font-size: 12px;
            line-height: 18px;
            padding-left: 10px;
        }
        ol>li {
            list-style: decimal inside;
            font-size: 13px;
            line-height: 18px;
            padding-left: 10px;
        }
        .moneyinput {
            width: 130px;
            .el-input__inner {
                text-align: center;
            }
        }
        >.el-button {
            margin:20px 0 20px 10px;
        }
    }
}
</style>
<style lang="scss" scoped>
.moryeInfoTitle{
    font-family: PingFangSC-Semibold;
    font-size: 16px;
    font-weight: bold;
    color: #498DF0;
    letter-spacing: -0.25px;
    margin-bottom: 12px;
}
.moryeInfoText{
    font-family: PingFangSC-Semibold;
    font-size: 14px;
    color: #2B3642;
    font-weight: bold;
    letter-spacing: -0.22px;
    margin-bottom: 8px;
}
.moryeInfoCri{
    font-family: PingFangSC-Regular;
    font-size: 12px;
    font-weight: normal;
    color: #2B3642;
    letter-spacing: -0.22px;
    margin-bottom: 6px;
    .moryeInfoCriRound{
        display: inline-block;
        width: 9px;
        height: 9px;
        border:  1px solid #498DF0;
        border-radius: 50%;
        margin-right: 10px;
    }
}
.moryeInfoLast{
    font-family: PingFangSC-Regular;
    font-size: 12px;
    color: #2B3642;
    font-weight: normal;
    letter-spacing: -0.22px;
    padding-left: 16px;
    margin-bottom: 8px;
}
</style>
